{
    "nodes": {
        "I-5rWUehEv-MjdK9gFw09RxfSLQX9DIHxG614Wf8qo0": {
            "url": "https://redstone.finance",
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "name": "redstone",
            "ipAddress": "0.0.0.0",
            "dataServiceId": "redstone-main-demo",
            "evmAddress": "0x0C39486f770B26F5527BBBf942726537986Cd7eb",
            "description": "Main RedStone Provider with all available tokens.",
            "arweavePublicKey": "xyTvKiCST8bAT6sxrgkLh8UCX2N1eKvawODuxwq4qOHIdDAZFU_3N2m59rkZ0E7m77GsJuf1I8u0oEJEbxAdT7uD2JTwoYEHauXSxyJYvF0RCcZOhl5P1PJwImd44SJYa_9My7L84D5KXB9SKs8_VThe7ZyOb5HSGLNvMIK6A8IJ4Hr_tg9GYm65CRmtcu18S9mhun8vgw2wi7Gw6oR6mc4vU1I-hrU66Fi7YlXwFieP6YSy01JqoLPhU84EunPQzXPouVSbXjgRU5kFVxtdRy4GK2fzEBFYsQwCQgFrySCrFKHV8AInu9jerfof_DxNKiXkBzlB8nc22CrYnvvio_BWyh-gN0hQHZT0gwMR-A7sbXNCQJfReaIZzX_jP6XoB82PnpzmL_j1mJ2lnv2Rn001flBAx9AYxtGXd9s07pA-FggTbEG3Y2UnlWW6l3EJ93E0IfxL0PqGEUlp217mxUHvmTw9fkGDWa8rT9RPmsTyji-kMFSefclw80cBm_iOsIEutGP4S3LDbP-ZVJWDeJOBQQpSgwbisl8qbjl2sMQLQihoG2TQyNbmLwfyq-XSULkXjUi1_6BH36wnDBLWBKF-bS2bLKcGtn3Vjet72lNHxJJilcj8vpauwJG0078S_lO5uGt6oicdGR6eh_NSn6_8za_tXg0G_fohz4Yb1z8",
            "ecdsaPublicKey": "0x04009dd87eb41d96ce8ad94aa22ea8b0ba4ac20c45e42f71726d6b180f93c3f298e333ae7591fe1c9d88234575639be9e81e35ba2fe5ad2c2260f07db49ccb9d0d"
        },
        "Yba8IVc_01bFxutKNJAZ7CmTD5AVi2GcWXf1NajPAsc": {
            "url": "https://redstone.finance",
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "name": "redstone-stocks",
            "ipAddress": "0.0.0.0",
            "dataServiceId": "redstone-stocks-demo",
            "evmAddress": "0x926E370fD53c23f8B71ad2B3217b227E41A92b12",
            "description": "Stocks, ETFs, Livestocks, Metals, Energies...",
            "arweavePublicKey": "wOoClMPZ_maAoE6OhCC8svn_9gu8rjuWPi3Grf05zpQnCzeFjzUocJUr0ta-muJDqfiTd4t5KR2qNxn3nkN2_kXsn5AcXnlI4UgnRdcEvsDh6yPMoMlAiAD6c0_CIgdiMFku5OTijNMtFYOybPKrd9Xdf_7_lnRZhU9qQXeBclVjmIzftwNaTFYI1sgApWKMxJ6301CY7VN9SOD9KBrFQJ3QjWqLrLwAyAu_Q6aBeQF_L-cZSFCiTfwyDptiMCdLAjzU9s55LNAg65pZ_OfFsvqylwiXEVIEDLUI3nmcM6OUHfyAG2TxoeXeem9nCpA96MiM8MyTWtOApGA3GW__j3fxutD0wt3_uGnVRZoypPqNuPjb5BraF0owTDYJQNML9Ddx1Hy6gMXjvjxn-MbAV7tI4KnDynrbtXPSZYjRpTpxcH9Rlr-bQAZWGpYF7OWpsZlPuE4MdxpQYhqLj6wfGCxTyzIjyt_t4zR28gWIGT5SfM-etcuXyy_chspsIqisSj781AP7YMTIxzyrY_LAAm-u5gz5VwnD8xDKIrYcsxLpC4V30HFWRc5vYtBQ0cAZYnp9AGGFD-6A7PnqQ1EYSSEHzSAqoYHaqxuAvxJEhG5AECdXBS3-FpgbGgVFEeQxIH7v9Js6-Uk85t23MjRUJFNo2JIlvyolmtkd3mf88qc",
            "ecdsaPublicKey": "0x04a628e8c2ef7d3b768b75404a48adcd667d0d59ae457bf19552ff670d2c95630480752e90b0c9fe8127feeb9e8f9e694919c730327bd368c985f1a5b26c2d60ba"
        },
        "zYqPZuALSPa_f5Agvf8g2JHv94cqMn9aBtnH7GFHbuA": {
            "url": "https://redstone.finance",
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "name": "redstone-rapid",
            "ipAddress": "0.0.0.0",
            "dataServiceId": "redstone-rapid-demo",
            "evmAddress": "0xf786a909D559F5Dee2dc6706d8e5A81728a39aE9",
            "description": "Most popular tokens with frequent updates",
            "arweavePublicKey": "tfkkt6lHR3lSEBNvjistpdGb8pR9UJoOVO-IuXRXD9PckAqY7TAVuDVhrcQDM56GZ_EUh6Eg_NRYd-EGW8SEQLHXtY_CM4P8563xUpw0XcZJbpOeScFcN5JdN47gq8vllOheO6-v4nRPLVabRVJqkXEqzdEwxQNYDkmPL-gxE0ziZcQRQZdJUzL5mI9DzwpPC86JBVwsBK71iuRlstABciIu8u77qyArkNu0pPig9OFQvT3Vg4OPuWXd83EhqEuN5gqVufyomkmL8X7agiEjDf-UQIfZrSYqgiJsWiVJ2aKHRhLZN17wdX51L21Cg2Sbyb3B1Roy5EgUUTdJ2MY7LnI-CTbBBJLKUHSvN67MDhj1OSBwUULc8bgVCzmfVQryIFmb4tucKvz7TRAWseXNO2MtMlggXa42Hx0sOTopbFTmT_r9glRLYw3QLzyJVH7Ltqr8QldoU-VMWtpo5cmOGh8jwVknSHqWNURbRCoDfAuwh8lpWXBjL_V8haaY0OKFT9Lpi1VW8o4Kfx7ED1VAnLcpVIoV5CkPs_L0Qy_G7XpgA02OAbJH2KvwxZPeSSymdupmr1KMc8iGz5B4a1HEcKggk5ETFfeGz5r0hDha3dwDj-dOv1jbADcdgk7e2xaLgw1CpS1XEHhAnhBJgAzJDJpcHKrxPkD6cUc7FbnsBCE",
            "ecdsaPublicKey": "0x041d45589c5055c867e857d8437e7d5745027d0a455801bc78cc856840510e76d8ffe578296a11a03e744d7a953e0f1e36c3a93f74268859e480203ab4d2f5718e"
        },
        "f1Ipos2fVPbxPVO65GBygkMyW0tkAhp2hdprRPPBBN8": {
            "url": "https://redstone.finance",
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "name": "redstone-avalanche-node-1",
            "ipAddress": "0.0.0.0",
            "dataServiceId": "redstone-avalanche-demo",
            "evmAddress": "0x3a7d971De367FE15D164CDD952F64205F2D9f10c",
            "description": "Most popular tokens from the Avalanche ecosystem - demo node 1",
            "arweavePublicKey": "nehHT9-CMWscteR3COOU455AmDI8VihUPG05kDryVl8wjv4iE_7MttV2rz3MTIicL1BNDQ246EnyIL_4qsqu8a_B3dZT8sVdfOtHqh48pDgA0MJ8ifFVUtyabr3D2ZSc2M4re79wm2SQ_S74ZNHV7zBIBS8pUHB20TfrKhrOWi-W0hfkxrqz4jIngXIPmE1ycYbaj9OHSVRFIB-EZN-1LmLZuy2EAAN0nOqJ-aYoblcGOu4WfZDpTXTfj-6-GRRBgeHen3MYnQuDneEYYrtzoCnimWGYrqRnpM654mJTW7gp_NIqLnXn3yG2aS2pbhn1_I0CavtKuE4PSACoP0UwZaGXEtanIaSuMDkeRdu-zhrr8yXZSW1inWdC0lhWPxAhzTYWQ00_OD6oj2FUtbXbAk_F-aPnT1Teb-LcNn6q-HbHSeZ_VV0ge5nn3zvBCc77z-Qfbgyd_a7dNwfy8sVkQc4-gvDJIftzxVXbUWnc3ipvL-PHfLsajg5Jkhteuvf8bIS0cNFS5u9pu3qnutskQxSymPzhkVmSyMWNe48Xp3ok4mUA9SuWTb6AskyoDVR9APh7JNDgeYINctgduqawzJQFxcVDuoW-1b8Gy79EYXW8yHowpAaDm6eYGTPQjnMpttThZwFiC_81JTXHXmDneKm677F2kO-1f6o4UWTBOEk",
            "ecdsaPublicKey": "0x0455e7c699a153e02de0e6d3c3dcdb34d1e57a9456205b0e124e2cac29b58be83c4faa2081d39b38aa3c41fa3350f50dcf87d40ab695c9c86709040d39d5ba9be3"
        },
        "6bZ3yxPYy0LHPqo7MNqw0PHTeIM2PR-RmfTPYLltsfw": {
            "url": "https://redstone.finance",
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "name": "redstone-avalanche-node-2",
            "ipAddress": "0.0.0.0",
            "dataServiceId": "redstone-avalanche-demo",
            "evmAddress": "0x41ed5321B76C045f5439eCf9e73F96c6c25B1D75",
            "description": "Most popular tokens from the Avalanche ecosystem - demo node 2",
            "arweavePublicKey": "TODO: find and add",
            "ecdsaPublicKey": "0x0428082ee0046157d0fba27aa9391f5651c41b9dc8b12011ed72622c05e55d56ae503039f912de7345dab28ae68d80b055082f74286b82ffc2c09c07d8b2e22378"
        },
        "OxQ-vLXcwTZdcdlCziC95duFylo8Aj9xBJ4XsC3xlGk": {
            "name": "redstone-arbitrum-dev-node-1",
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "description": "Data feeds from arbitrum redstone nodes - dev 1",
            "dataServiceId": "redstone-arbitrum-demo",
            "evmAddress": "0x16883583647260AB2e2BA63c4c38D9807Fd7296e",
            "ipAddress": "0.0.0.0",
            "url": "https://redstone.finance/",
            "ecdsaPublicKey": "0x041aea25f013dee918d88063daca2d0113fa41a95b66440d8d8edf09a3d397045f5b9d391c1e50e6998d3b2f95918899e382f444714e64d9fd689c92d4a2535ea1"
        },
        "LtVolyIqpjhCr8fw_ue_-Y9xaH5IURpKZclyJbsu4SE": {
            "name": "redstone-arbitrum-dev-node-2",
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "description": "Data feeds from arbitrum redstone nodes - dev 2",
            "dataServiceId": "redstone-arbitrum-demo",
            "evmAddress": "0x1053d519Cc8C8cd7e53FecbFb13B6F0ffbD4c8C5",
            "ipAddress": "0.0.0.0",
            "url": "https://redstone.finance/",
            "ecdsaPublicKey": "0x04d0f2bb463dfe89778fc6fe68ed6945bbf13f1391369942d78607cc4eac906aa63d082caf6c81c3c191daf4bbb7cfabbf5fca1ca762e02529cf700a15ac0cd828"
        },
        "tTTthGBknPG4MWH5y-zDKO2dEF3Fxo0gPbzV6PLNlNQ": {
            "name": "redstone-avalanche-prod-node-1",
            "description": "Most popular tokens from the Avalanche ecosystem - prod node 1",
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "dataServiceId": "redstone-avalanche-prod",
            "evmAddress": "0x1eA62d73EdF8AC05DfceA1A34b9796E937a29EfF",
            "url": "https://redstone.finance",
            "ipAddress": "0.0.0.0",
            "ecdsaPublicKey": "0x04ea48ca25db5d352ec62c4f92e8c115068e92a327f3536479a0185ec6e0f8d26db4409bffdc8c6ea898743f908861a47fdb4a52e44d129a56418a1c08008fd425",
            "arweavePublicKey": "26hxzuODmUGbc3c52ZFz1zLeKdasZdcd2EmcLBl1PNGD75S9F5J4a0WDLeklG0YZEIU25besIa5bvinF8vCp_ebdJZUnhvheQBv_0-aoR2DBBoxe5Og942NMLEDzQl9mSP3y4_qGKnf3XQc4tzP5C6HRDI3b8L9Is4sAckEVaR5NXVAoOsg-YST-2g-Uoqkw1-ZavjL30ABSDejQLNRyZ36uzxRe5PzXtG9Oy8-t_Tz5SafMnzb68dbyBTCD2172fjxP8pXsIS-a6fNvMdjIR9BKcGHXh2_xvC0-TeR_48HOw0UHLIRqW6C6XkgWLz_DDJdVuv91f6RA_-BJPmST89tlI2S-Nma4XOZrBAB7A9X6OMoiHZfxKH1b5qs3UuTWBJgx5A9GEJ-urxYAfC41-WvfadlRFOPkgtS-4rwnq1u2pWj2Q2jxyY7O-Pj4pZSNjJe4yNhHR8mbgm58zeI_mZU_Y7RcH8W9IU5RClfuaBSSJ48GfkTU2cXYIr263heAblt8pcRfNbtEw58iWtJ7wFeEgkcsyi7A-1B1o3a61xqbH3wZ42qAMe133AGXrdYHoJpVXuxk8Ei0QUYFdrlkwCCzkALG-GYavaG_0HAg74KIHsNEvmbeLpl-SDqQFk3t4_DAsTEKNbjhGX58SRFgQ15oyp_lEIC6JydNHwDNjq8"
        },
        "azkge6PQ_40JzMdcgkpj21jxhl0JaQg1o6yPSCekJ4Q": {
            "name": "redstone-avalanche-prod-node-2",
            "description": "Most popular tokens from the Avalanche ecosystem - prod node 2",
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "dataServiceId": "redstone-avalanche-prod",
            "evmAddress": "0x2c59617248994D12816EE1Fa77CE0a64eEB456BF",
            "url": "https://redstone.finance",
            "ipAddress": "0.0.0.0",
            "ecdsaPublicKey": "0x04f5f035588502146774d0ccfd62ee5bf1d7f1dbb96aae33a79765c636b8ec75a36f5121931b5cc37215a7d4280c5700ca92daaaf93c32b06ca9f98b1f4ece624e",
            "arweavePublicKey": "uw79mR1O15fps5uWLsvMTiE65cAzRJs1T3uvdmEksGINCsZ1yxJJhu9PYY_OniQEl-Z3JZ1cF42Vpwxaz2JF_lY1kPXO72prefyF4ew-Q2c3WgHg75nODMZ6Xq1xlIYKfEetDvEX-MhIvm454TnHOgbvmZNyi54XT5SNnmjvtQoEfKeJ1RRRXcOubt0NgDDpWbEmvQTSFlUT_m14-H0gM7yHT5C2UjS_wCM41THFs-Aw6Uce8ROKy1rAznAI2eS3O7t3evI4f3qHQxQXuTOOUqGUn6A2Oeys0CJqnb5bJrhk3j30VxBpotDwAyOLfaLB4NEjAkuBxHWIzI-34RmIgTOQEmZ3e4cOu7ymYSx2R92z-59bOleIpfzzmagTYw7nMxbvEyb75U1B3Mz3iK-YjY7EHOb953l_QcQwqhlSqFNTXjbZgchDD1GrMULolGh1DFeUflTHg-s6XGi273Ri4hhTyLDFQTUw18J39ON6D5IAg9z6PoyTOW1MjvdLoeY3zVXH7NGq-bmwFup_9v3gd-h7ZCnnu0qWQzxDepBySA7fIo5FFAwL7KoUOC1wnc62rocwlFJ3J9vp1mvFUpkSJxCOtYR7xLEZhcMBxnrewcbx5iImdPx8N3M_94D6osrKxxmH8GwE5RScsW2ws2jfruNhIxgQkY00d_q5HZ4XLws"
        },
        "WMUjPDhwoYvDqw5BNAaCt6-iLs8fhzwC0L_rT4lTxFg": {
            "name": "redstone-avalanche-prod-node-3",
            "description": "Most popular tokens from the Avalanche ecosystem - prod node 3",
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "dataServiceId": "redstone-avalanche-prod",
            "evmAddress": "0x12470f7aBA85c8b81D63137DD5925D6EE114952b",
            "url": "https://redstone.finance",
            "ipAddress": "0.0.0.0",
            "ecdsaPublicKey": "0x04626f2ad2cfb0b41a24276d78de8959bcf45fc5e80804416e660aab2089d15e98206526e639ee19d17c8f9ae0ce3a6ff1a8ea4ab773d0fb4214e08aad7ba978c8",
            "arweavePublicKey": "5f6on6vYsq3Qgp-c-yuV0SRzfjKHaRTcfKNCsXnB1dkfKHCncr5gFTIT7244Nco413xRMtKxmU7-05Z4cRhVgq-zlzdRFj-7VedtH0WlJpxILSevGILdAcklt6K6q2DDZWmvNlbCIXfXYum_ajNo41toc1FXq-f5AXc3FJEU1sWLxwAfDjrPvEdj67QlFAIvviM_uwATmjWyVTk48CLec-53_nsf-cGA3euLkhozLswTSEs_Mn9T7RSRVZ3VlpwGFhvzwidKclRsoSTUJGV_c3AsRfu_zRsURxMz_p6UDF1MaSo7RP-NgupW9EQ2itGVTMcNHKalT3dKeMUHiCjnxldAkZCjOzfL9t053R_Zi6pXJ_fCD2I9ysZgp1yL3yJyfbGg6uxgNIAeSo8epMZQzXJpFxKB-VEVWEFic4loMX-wCYpulMp--azq7NBAt5UPcFtqs688Ox9CmeCNrMuo82w-1qZCUtwU_orLo8K7QSwPOmitd1pUVuCZMyFL05zRmJB3cxu-PCncpiqVN0g9v3MOpGa5WVCNHNKAXtuM_y-6s3XmraBsV21o0Dpc_sxX2BU-4WHmkToH9jfHGVozMiNCM26F9cEdFy4ptz8eOIuYgqhfksgfw0zB3jJwBwPJuDqhH6n-5SqysyzOkzWAzm3reVAEXuj1MAUx3C5qScc"
        },
        "POU2msUaCzhDq32_hSsKrCH37hHlUvbqfK0-Wjuz3Is": {
            "name": "redstone-avalanche-prod-node-4",
            "description": "Most popular tokens from the Avalanche ecosystem - prod node 4",
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "dataServiceId": "redstone-avalanche-prod",
            "evmAddress": "0x109B4a318A4F5ddcbCA6349B45f881B4137deaFB",
            "url": "https://redstone.finance",
            "ipAddress": "0.0.0.0",
            "ecdsaPublicKey": "0x047cea60b650ac5e72ca9991cd3c365c17db58cc6c0f385af0b05056a2d6068480093b603efcb47410e8092d60c5b85d27a3e43dc6ddc4077994e6eabd9a8ab9cb",
            "arweavePublicKey": "2IQy3vmXQDDGJRXK7Pock02VBxtoVR7HNXMAv7QugQkZJhEYqIq4n4TANbSo0JsMVDg0NsqtPjaRSbStr-guSlvi9behNVOj6HEzcri6YMXq80MIOw8FrCWTp9M3co1mcZS8nGE82MCK9PYa8JAlVQTnZ8ahovGT39Qc3K0q5hgyR0a6auliDMAALtaPYfH60Kt2IwDlYv67UkTjarKdbQG7LZDMwdG_0PIFpfbgzW41cxcLymPmnHO1UY14UD-HuyeMclx215MhtH9RcfCIaTZZAxpGcTU06nCxyqNiff7EufaahBg5e1D4tl_3nBzUOs4OOvfk6nDts1t4_lDO_KtdxeLSp0ADLBTMZ68TKqx53Z5Rlo8PZzO85CEAQVsKb3JX-dMl0BvoRzq7lvkxo0916YQUJQgbl7ryO6m5kuekk3j6xu3ccLDdfeHmLbnfacHBoRnPbJRXM1Yx11ovsywdixyAqLCf_Gr_gGP_Sb4TunN_-_M2JVvXKsK70TtO0AUWp6nXUiN_I9dCNS3f2Asu4yWxw61dXpAiBnJEHBEKY4ctflsg1JhV1abLOuAW7xd4v7mt_XBkEmz9zNodRQux23aj_m_79wucbxkq04s3WsL6NmkoRPJ02vDdUKYUHZ7x4JkkLFmdC5kI0P9NzOMkl1s5dKAF-TD_PFVT6R8"
        },
        "9QvwJVg8PapGD6VaGPffcYajExbnJWG3HH5hG76-5xM": {
            "name": "redstone-avalanche-prod-node-5",
            "description": "Most popular tokens from the Avalanche ecosystem - prod node 5",
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "dataServiceId": "redstone-avalanche-prod",
            "evmAddress": "0x83cbA8c619fb629b81A65C2e67fE15cf3E3C9747",
            "url": "https://redstone.finance",
            "ipAddress": "0.0.0.0",
            "ecdsaPublicKey": "0x04a66e3eb25f4110aa9f9cae8864b39b249568c094123737541e897d924253c84279bfd0f500e85f75a0943c41ca03c95fe0c3c7339e0499b5d6993a3f580aef85",
            "arweavePublicKey": "7RqQRi3ft2uHow196B23oBCy8og5BEe_I-VEJ3vb21hTHy_ayICIrc3FOcrjJ32i9ExWlLy9EuqsqWCczk2G34OS-N6mn9U7TlxE9BiqNydGCC7nEZ_fYj8DHPCwr6IEEWe-47uY5wHQZPstzIoAN4_zEKQT5douEctfZCaT_SN5-I9soj5Qe7iirpijQmSMfRVLvFQtVNqM_GxJWE48uYzZ9owUrYwUUvxgGFB9EfQi1dVjxThpuImughyB7KWc_Qv226TSMCLPLD3nbtAmWBxIM5SCF2nWSScB_-rGNiugXil3QZKa2CJ6zgXgdigGlufdJkOfN7DT9DocfutUtjN4Z3Z_FeliiWs-6klhYUP5RdEAZrF7Kf7E2fvpijEfsDbeFK2ZjpNrNND1F6gLIzfC7kT-bBpRqpkRQuP2Bu7O3fgnsYG5EF94KwCBeCwB3MYVW5v0S26SGRtvN_qU6knkIxiOBMsTDJaFeEKfRtW7tnLdMCyM5xIJ3Lzk566TeiOq4ivmtMRs6PT_8cN6B1crqYstuRhoYPTggIiO0WJdIKfbOMHRXbfSwgyXsvADfoEu7SywQtWnKAG7K8tOwIdEl4GJb-6yZD__Z_jpO-oLFLSszDxz_Fbba_gU7U93MwEbtMfq9Sekfqq9Syl98D4KR8ZtJdgkQTatUCc4Oj0"
        },
        "HebMpM4xI07ev3jbEWamFNmRxu2ojflQfh0b2lpjFoM": {
            "name": "redstone-primary-demo-node-1",
            "description": "Data feeds from primary redstone nodes - demo node 1",
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "dataServiceId": "redstone-primary-demo",
            "evmAddress": "0xdE13FdEE7a9B483129a81794d02FCB4021069f0C",
            "url": "https://redstone.finance",
            "ipAddress": "0.0.0.0",
            "ecdsaPublicKey": "0x041c320fdaca2d381101d85f2e0fcf9c6d6ebb88f2a3b71a20ecf229c1cd0e81b541d6960b58666fce5abba84a460590c72f4e871d87b451f0b7823f98d0c41970",
            "arweavePublicKey": "sfTEXB9ljPagrv7XodJlTs0fM1pQdsCZ5iHC6mWsFpYgNUIPX6vkA4yoEjGh3naoLz5qKYtML3RIyI6z-syI2JV7TFUyEGunSDyzWg7ZZDxeG16RwpUhsPSJ7z7eSA91YAVAYJOqzgcOI_RsdgAHrtH64apLp_8uJcndJ_oXpEuoKRO2SjE2wSnwkHodHK6F-koov8rNY6edBLfmfjtKzzS-8X8g0oKSVzNdiov_WZ0UlUelsVhd-KoC6S65QT1yg7TbVRQP0ZBhfMvtpoZh8y8ftj9VaTsIaZcu77LR1tc6zCLThZAbclGYPM1EWxJ8vUDp5XYSxF6AvTFAj2NpvHDW94Mln3ZYTv2a4UobOruO9rA4npduHlo-5klgFxJGVjGsK-w18pk13KozmW5P_DZPwK_5rcKwvUakUYY2OCF_lismG7UiIPRGtQkoWffaZDfuMI2ONidtGskqFBjgySFsdMt07Wt10h5WbLI5xmUoBpdrxQarfzECCyQ4QT--GOmtaS8G7PUbYMtpgGk4nxZnqqzNidRez_0bQLQbsIMMej9HTNvkiwW9w5e374Ek8JjiCw77yUcE2e1vUAr-IqrHD5LFSmB-H5JM_GoIGhTEjYU_-v3RkeiklwmuWBPzLdyL2mMZPANacW6cOWt4g6DyXTjRKSLY0UiPuGsJT1c"
        },
        "-qrcZLkqrgS9cNhLvy0c7gzpkGlAgwbZ6C3_hk2vvJQ": {
            "name": "redstone-primary-demo-node-2",
            "description": "Data feeds from primary redstone nodes - demo node 2",
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "dataServiceId": "redstone-primary-demo",
            "evmAddress": "0xad05Ce43E0bCD11345f08a28995951DEc30D5226",
            "url": "https://redstone.finance",
            "ipAddress": "0.0.0.0",
            "ecdsaPublicKey": "0x04440a1d2f497db99d7282bf6ccaf133995ed05f992c177c1eaf7b861a9e64261d1da123bcefa6bed232755197fc5082d42696e72aaf42b45771eba336d693b9b3",
            "arweavePublicKey": "1OgIYqYOFlYr-Yo_cFdnWisxRv8B-2x2zroc0Kcn6_f8yiD7yZBVFQOBOh-kVfcmVrCuBYPywvJevmMgIkg2t4xOsoG0y_liTYAYKjhT2tiV8xta1ykq0xvLiOVOQY4g7GCzcCV-ZPE9xc4bI9ozYnpS4NRjJM8_mOnS9I1lrefx7pjHsrTyrB3YGmmW2WJm9o1MS225PFzDmPSEghw3jlVsf-KtDXqxCCpRZGTI1kKZJ-Snm9k_19tK9MHGfVGsmT8pZJjsJmLJXHrLl1KVgDXsjxxcU2f6ZycSj5imP024Dc5w65aV1JXEey9a0wLz4y1DpxPbrUN617zL1-6xA3PyQBbbI2tpM808UILlYAYWZPBeS9-87j9Abn7wDtBuqGGribdYH-KT61lF14xozCWj8SwKk9ZiMsuk8XhTlmzWa2_xwMpDFX_GVwyueZWR4lx1PK3AMWOVfITj8xU9iPHA2msL9AZ9Hj1R12k0DXGn7pjHwUgSNXCe46nIBbjEoUVtihDGAddwXAyhBVSFriDsfqWDftYEigGbNccQh8JVfRJraP20saJLlwnFL5rYhd1nSFzEwi3yIFA2X-PjudxWHa2mTGwZYCtTMkOa2E5ucQUp8eA-N-fQJF1YNNeznv5IA-yN4e35zuVUC7dNq364bUogDAqTMojRFMzhpas"
        },
        "R4ih95AvybIzz3uKuEmpBee17J73CGkSk4bvzU_IZ_I": {
            "name": "redstone-primary-prod-node-1",
            "description": "Data feeds from primary redstone nodes - prod node 1",
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "dataServiceId": "redstone-primary-prod",
            "evmAddress": "0x8BB8F32Df04c8b654987DAaeD53D6B6091e3B774",
            "url": "https://redstone.finance",
            "ipAddress": "0.0.0.0",
            "ecdsaPublicKey": "0x049a0e6cada7938a0fc616578aff2b11337d90ec4e99e9fee95e57e9d8371562b31698225fb73c666ba586842b913e67ad289f374f7a46fc873342f805a8683d4e",
            "arweavePublicKey": "nLzSke0nHxv_kp-NVMrjlXQtVZpNlFyUybEIVZx17IAwIBRrqNJBJ5pR0_nbgtJTwXg32YiLs_1ETBaazj1HL22au_kSxa5zkHsomEi7wmioAYGN2LGcaeUWlrPq8zwDk5IniSHkGdJ7wuCLig2OyVoYpINGvRchy9NOpWylAyJjHdcOkEDTAFHKeKWAs0SeAo-VSr_V53yyRSQ-ecOpcSAMWtx-jOkxgNoMPHAX-79arV206Q-sFjGNobceNl-NhCKOEOy4BjHCQm0XVUApXQx_hVrej_j-DE3QbCGCcgskrvpYFrB7ZGfCVYToH3GDe-orbCH_Qa9Q88uIR9-hIRWnTcES3RDdb-72bvVdJVyvMqKWXdtjB-3aYS6Klyx6H7l2hYdesQzEv4IqOFctoyUqq3ECZkW5ZTZzo-vg2QatwquY12kkOB7PAkZRYjc6HEoWnZy2qv4M4RnpMiYpgjww4kcryXBBZqy4X-xAkJAjWt-XDTpIWmLBtgu9plZiOhmqgARyMtuF0NspFZCu7g5PfeQVo5lzv16CPXimXpPLhxiVoOxjSKHTeuv0xIPA9cNO_6K0XTSdUYlzkDdcnQIg5SKI3b9kE3JSkiVjjk0EfhZdrBaWolS__NNEBzqjMU5TR4bk9BQ5CTOaqXDIbCEK2_GMaJ6pzjGPfRZaMu0"
        },
        "WM9CHZ6CKf95z47krdOFFKX6kuOusBlyIVSJmcjdrEQ": {
            "name": "redstone-primary-prod-node-2",
            "description": "Data feeds from primary redstone nodes - prod node 2",
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "dataServiceId": "redstone-primary-prod",
            "evmAddress": "0xdEB22f54738d54976C4c0fe5ce6d408E40d88499",
            "url": "https://redstone.finance",
            "ipAddress": "0.0.0.0",
            "ecdsaPublicKey": "0x04683f7100d67fbaccae68db4d146f7bc3090c5cbcb1351e333efd58d42f609ceeada12b0af28a6e789d6edfe25fe784dd1e86798240d4cee9f60e56848cb55965",
            "arweavePublicKey": "yE4SZn6OcwBG0Zb4Awm9bS6rqJMcy3ldVosxCiI7bwCYsRjA_fArgtuDG9b-B6xxEC1pY0TBAJSN3HKo8XIDGJNh_xHoA__Yi_Q4OGmtFqGWbQmP78piaJ3vn9nR-vwFGPnZPLzKinzKxHKNGH16gkhblszX59oUlUQP1o2slFmD6UsJX-K3LqWSN5u_1snW4eNpyPY0UbtuNvVvSNJeaT4D7zPYhZhpt5WrYZBzmTq8HoK290wb1L-Okik072aw9XMrFNBiyyQd0thAAobferTm8Ch-lcfigwkaHDEtwD2GoJIJZ69oTX810OFz-m4JomEBck4B1dSou3aPJhjK_bnsgLztiG6N7DHps7XOyD6vVo2rJpYWMPmxLtWeXIgmNSDjypUuDH4hJOT_kp3FO5YvHBhL__RY__wNBvJ5XizilD6Rrom3PQMKmu4QWY84JDE7L1QHrwsEo7UKYpT3aNF-rrFiYYTn77fLN6iKkzouLf21etNoTqRAo-ftzTiiUtJ9ubBnYd4ne7AKRzZTS-dEOImA8w6MGjN0rkqaI78ihgIO9Zu_BClOZdWqRg3j-nwyzGcOcQeKjOU3HAWiotuioLBy603utiC7lRl8g-0VprAZfWOpJhfTX1EHyoI3AAY92joj4U_Cig8-9tCRh06BN3HaNobdQgkLeEk5KpU"
        },
        "9syjTFZp0__CsqnMjKEo6sjl6_yRWYm7TQjcD0hFEN8": {
            "name": "redstone-primary-prod-node-3",
            "description": "Data feeds from primary redstone nodes - prod node 3",
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "dataServiceId": "redstone-primary-prod",
            "evmAddress": "0x51Ce04Be4b3E32572C4Ec9135221d0691Ba7d202",
            "url": "https://redstone.finance",
            "ipAddress": "0.0.0.0",
            "ecdsaPublicKey": "0x04490f08821dea02d23683f170459aa6317dd65b544aac1df514f2e657f87ac00b81757edce3e78785e00dcd92e2dc8ddd9a680dc929a0c91062d87ae000a217ce",
            "arweavePublicKey": "uFmwr5_2RAokGbqABO6edwcbsVhctDmKWG-velfxBvqjGU9YUc3QIentdWPBEaA4xcXlQC1fz8-woXEqqUjTjYyDpZ_1FEaftIVgQFyxzT2OW--8Mnox7uwqvmtAZLejxRibj4YYyqxO81tF4m5MVpgewoBBep0p_nCsBtN50iV4VtG6oPOOiFa9p1Hk-h2ja8K57qfn3Pxh6g2jmk2FVCo9JAgHCoSat8IT_QQ9BRw4pOTyZbTzM-7K28FjKHJqaQ8fRK0bZkMmzt8LhXUZVQCqHWeaLaMoNFikDTWZ_ia-SEr6_T_QlybPoj07TIwkMpVNDMAFcmltHBoLtNDfXG3fyDAQe9fZPp4H4ER5VLi_MoEB0UM2feLZNIpiwSCWEtOUvamBaCbtRBObdQZPphbP3Iof2VZvavnwHRE5FHnLFuXpnwMPmJADDwIdetUxNKW42QGl5nG43IC18ufYBH-hV6rEdlxd5hHPqw7wknGQ9fW_686Z_qE2U7Lc37OO0a1tT6877IuOYpO8GGm5YOS0lc0A7jP5RBO5EWsS_8xZyKKo2aUP5f39-WZid6lRAtwseSvr9oJbRLS5jfCGb9WTJ0VzQEgCpcp1eVaMSDBvXb-4p6lOr1Z7OD0loSHiAsNZWUdAqmRLAZROZsXI6r_z5J_vrW82ZkFTRIeNdeE"
        },
        "4dRTLZeJPaDMhGE2jUndt-3KIOQxO6Y_BCV-3KIiPhI": {
            "name": "redstone-primary-prod-node-4",
            "description": "Data feeds from primary redstone nodes - prod node 4",
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "dataServiceId": "redstone-primary-prod",
            "evmAddress": "0xDD682daEC5A90dD295d14DA4b0bec9281017b5bE",
            "url": "https://redstone.finance",
            "ipAddress": "0.0.0.0",
            "ecdsaPublicKey": "0x04a221496ff2436da121796c11f888cc4e2155da7e36bd1148c9466fb54627f15e42d4cfed06b1570b7ebe0c589acf91fd314cfd95303c9894c0be43432aba619f",
            "arweavePublicKey": "3gACwmU3yQTUU4eHYWvWYzXh96royDi3Uce7shiBk0TeK2aaKGqR0pswjbWLUF1A22JmfuD-ItrkKKAfX5Mhj7GgFrDcPA0D2soA0hRQGh5qkLi4iSxXb8BdD4suG-dN2y2_Zn1zMmcoWHHxgxmr-mtD5t4H1CrIxFF4dLQWJ44ydFwTmIxvVaZzSYA8sC5z2Q_hIzr90Z1DdEJUcJomXbKPeymeuVeKxCfPab93czr5-wm11I_sNx9dTTzRW90_jiZ99aSbSVY_R6jWQ2vAfbCqoHIqsHEk7Yc4WNjXlh7aZMBi5hPW3zrnN-ZnDDLXgBu4KrBqAFH-8jL4sfwe51ifKkosRu0DLx0kItpp3mgkKVUcsR513kQE49bxmFhhy3dbyUoJktetrWqg6iMPHMMB4ybXHsoqOHXou1vPtLTCxtNvTZJvUes3v47fs-04ThebpyAPPEDrVqt4mNCYzapvDE-RgWrnQrKLAIbXJspcggro5u_rdNl4hSDGgmFJhXTu2AIVoAjNTk9ARXtgo4K8RWcQKjZ8qotq30NNwqNE9GVk2EEJDnj-Jj_1_Dtkd0jozE0VyjaA9eT5NyZ2s1tfVS2pbNCC18sezte0PGeAwD7EqoJoYxgbmX6MPQhfyanSc3KSYi9fi_YYYmHT0n_uCUy9QR2Zvz5895QETB0"
        },
        "wZv_gFZgozLm2xjUaMZPzvgr6E4VcUlRZMXZOILfBXw": {
            "name": "redstone-primary-prod-node-5",
            "description": "Data feeds from primary redstone nodes - prod node 5",
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "dataServiceId": "redstone-primary-prod",
            "evmAddress": "0x9c5AE89C4Af6aA32cE58588DBaF90d18a855B6de",
            "url": "https://redstone.finance",
            "ipAddress": "0.0.0.0",
            "ecdsaPublicKey": "0x04f23aa199aeda6e55628e0ec88194c47713ba260898aae4483fb2f166c7e29b1664f817922059736358de71bdf374b3354db58d15f928a6fac9d1f61e284019b7",
            "arweavePublicKey": "2p14sG942MfNwRQnPPF78vdxXovnz0hSTUYiSmCvPlQHojPQyDdfX2fbML09kbkeq8TNQel7BzShDVDpfhM21lXDQhvW8kQTSk6I2qilZIbYRi4i0omgHLw6QnUOGPBdxUdbRSq63gH5A0aR_ATPgzKNwSZpViG3CODgOVRUWtzWlaHKGx3D5oloKnS9nnJJlZSh2sbDfq7MIOlZEHDpunERLByLlsxi33oj1YsrcV2rP_tUUDbuDNLWhwHorAjZqDKmPP0FEy38oABrB-NbDNihPs06V2wMNb4U6tlLiEcsfNTagWpQ8re6K3WpK5XKBOcaOhVqcmo2axP1go35pjV6RuISCQWGGqvnyeAsKdGjDANtSk5RYqjqy6cQTJfEKrHZbv2MaiLr5EUsnzP_9u4ZCo8vcVXj4BLLDIYNvm4b_Ik2j8ZoA432u2hAMtQYZNqfencj9kiKzBRoeDQF4nsJOQ5YUHkyS_a1Vnq0KPslPbACApJZntCz3LR2Te_ZxJePM_v9O6jtX2bTsRgkjGF5dWD4OFda89mFSKn9ZBzgysMTWiJsLYnnqPVW8zIP2PjG7VgfkpEIDzWFbCJiX2aevGT94P5pTuI9AF1cyKMX0kxeDibbqg6m9LixPqY0Kdm6pOwGa1RWP2tprPoQuS9uepP1vyzJEzk6jAyaQqU"
        },
        "ckah_Yc4ZhzQbx27Lgo1Ad1yePneQdkusER_c49KVK0": {
            "name": "redstone-arbitrum-prod-node-1",
            "description": "Data feeds from arbitrum redstone nodes - prod 1",
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "dataServiceId": "redstone-arbitrum-prod",
            "evmAddress": "0x345Efd26098e173F811e3B9Af1B0e0a11872B38b",
            "url": "https://redstone.finance",
            "ipAddress": "0.0.0.0",
            "ecdsaPublicKey": "0x0437026448b1a69e4be9bcee0789199c50e93446c75eb697efbeb732fa3b51d130f92d6597de811106511ba39f712f8cf025b82e0003a611b9f78804e66caf46d8"
        },
        "3HHevuTyFGYJJ-nBPonvDaB5oGIZbCbZtF0CAeFB13s": {
            "name": "redstone-arbitrum-prod-node-2",
            "description": "Data feeds from arbitrum redstone nodes - prod 2",
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "dataServiceId": "redstone-arbitrum-prod",
            "evmAddress": "0xbD0c5ccd85D5831B10E3e49527B8Cd67e2EFAf39",
            "url": "https://redstone.finance",
            "ipAddress": "0.0.0.0",
            "ecdsaPublicKey": "0x046ecbc2bcca15fb4a44b88e85e1433b22ad09b7960370c9dba379e09f37ebea0cbc3fb9a60f39df2c4b7cd8b885717ca1dafeac35e9edc5b550d0b61c4a7e581f"
        },
        "AkT1al4MbVqV8w9LXIvdsE-2xm_moP1ARaImUlqgDZA": {
            "name": "redstone-arbitrum-prod-node-3",
            "description": "Data feeds from arbitrum redstone nodes - prod 3",
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "dataServiceId": "redstone-arbitrum-prod",
            "evmAddress": "0x2F3E8EC88C01593d10ca9461c807660fF2D8DB28",
            "url": "https://redstone.finance",
            "ipAddress": "0.0.0.0",
            "ecdsaPublicKey": "0x047f0ac528b5b5f306b6488965bcd86cce263487320caaab7703171c8cf461eb692cd35837ff91d243d2972fc4c4598d08d8e1f5795ecbb1cb974087970a5783d8"
        },
        "k9ke_oPBAy4bt-Vu0_kwRcSx4HwcQl3Jd8h7dmomfrc": {
            "name": "redstone-arbitrum-prod-node-4",
            "description": "Data feeds from arbitrum redstone nodes - prod 4",
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "dataServiceId": "redstone-arbitrum-prod",
            "evmAddress": "0xb7f154bB5491565D215F4EB1c3fe3e84960627aF",
            "url": "https://redstone.finance",
            "ipAddress": "0.0.0.0",
            "ecdsaPublicKey": "0x04e051f36116b6bc953f2a94825a4931f6fe6e3a3f218e1dab2712a2a31fff450d1e8f82dcb3cba9dce6dd382d6b0e259d2a896f8a10947a234c08e6f424d272c9"
        },
        "7zqOor2CATpcOq_DduEAsJ_8us4FJ0BTZbMjLAH4MsU": {
            "name": "redstone-arbitrum-prod-node-5",
            "description": "Data feeds from arbitrum redstone nodes - prod 5",
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "dataServiceId": "redstone-arbitrum-prod",
            "evmAddress": "0xE6b0De8F4B31F137d3c59b5a0A71e66e7D504Ef9",
            "url": "https://redstone.finance",
            "ipAddress": "0.0.0.0",
            "ecdsaPublicKey": "0x04b41ed2eaf2b8b15232ab7d25458d1b2cc1ef02200ab50f912307cf832f619aadf89800ac17b1c3e40c4bebc7b4305bbec558ca5e60acb034dbb6c09273e29fbe"
        },
        "00HZS36ZwzP43q25-T_UV7pYlNXe9ChwDpNiM7A708c": {
            "url": "https://redstone.finance",
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "name": "redstone-fast-demo",
            "ipAddress": "0.0.0.0",
            "dataServiceId": "redstone-fast-demo",
            "description": "Very frequent updates from the fastest sources",
            "evmAddress": "0x69f70Aa9828Ed876d49e83Ee006b9d333BB406d8",
            "ecdsaPublicKey": "0x04d048bea3bcf01b82162703a7f59cf51d91a95a8fd3921b5a858a8ff1c1b858dd337918cb7969c695711f590779ffb9a1028d0205074f11d9fd40e905f6670d0a"
        },
        "WPrl0TkGtc2zEfa-SmpDd4sh0AkyMHMGZ9rxATyDTN0": {
            "url": "https://redstone.finance",
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "name": "redstone-external-demo-1",
            "ipAddress": "0.0.0.0",
            "dataServiceId": "redstone-external-demo-1",
            "description": "Data feeds from external providers, staging node",
            "evmAddress": "0x8e959CBA422cC14AAE2b1d36970293E955a05fA8",
            "ecdsaPublicKey": "0x04910c377ce317764b79b05769d5a911caa2e513485f64b261a38f7e6107d5368d41c346c8e3d6fd9ea0158e91e6df622721413820f760e7031dab4aef544f2de5"
        },
        "HJTZ-0JAX-FrM1Lh6v-jNUWJQu6BSN8jFffI4ipqefI": {
            "url": "https://redstone.finance",
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "name": "redstone-external-demo-2",
            "ipAddress": "0.0.0.0",
            "dataServiceId": "redstone-external-demo-2",
            "description": "Data feeds from external providers, staging node",
            "evmAddress": "0x1f9D87e3aE042B2A3311D72eC6f44A89B24CEed8",
            "ecdsaPublicKey": "0x0494635bf9e9841d5d4fb5fe62c7da5786d519b4e63cbbde9092b3d4555f480547abbf0ba4bc5dcfd97d22b5b154ae969110b662bbfb700447d7e6f9ee8f86cd69"
        },
        "DKWS99CPmosyDcejUMVs-QlhuOV4i8bL1D9A6TeZipQ": {
            "url": "https://bwarelabs.com/",
            "logo": "https://raw.githubusercontent.com/bwarelabs/brand-assets/main/infra-token/svg/infra-token-circle.svg",
            "name": "bwarelabs-demo-1",
            "ipAddress": "0.0.0.0",
            "dataServiceId": "bwarelabs-demo-1",
            "description": "Data feeds provided by Bware Labs",
            "evmAddress": "0x4564a9FdB414c320C74C908f60c204e486eC1e2a",
            "ecdsaPublicKey": "0x040da4fe87038cf59484ec5a183c8da8e04d4ab31a3a73f4a72c1ce3b0ff9048a4e53dc954cff85d986881b8c8066e0281661eae1b8393cfc342c241233fa11616"
        },
        "iNSySDPdkm7c-yf6VBZTf-g0LRmh53H6Y0KrfwBcncI": {
            "url": "https://kaiko.com/",
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "name": "kaiko-demo-1",
            "ipAddress": "0.0.0.0",
            "dataServiceId": "kaiko-demo-1",
            "description": "Data feeds provided by Kaiko",
            "evmAddress": "0xC92Fb6e9383BCC90f8299AfA519ac6bf7606A9F0",
            "ecdsaPublicKey": "0x04a4e9489e8e36206427cda107c566702de435055b83235dd787601af4a35f7fcd1b0347dba277a111c3ab756cfd87202f1986797472a1603581dc5c15a954dfe4"
        }
    },
    "evolve": null,
    "canEvolve": true,
    "dataServices": {
        "redstone-main-demo": {
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "name": "RedStone Main Demo",
            "admin": "I-5rWUehEv-MjdK9gFw09RxfSLQX9DIHxG614Wf8qo0",
            "description": "Main RedStone Provider with all available tokens.",
            "manifestTxId": "kXYM3AezlROPoEDCt_802-k9ZxeceAWzHtrERmR2zEg"
        },
        "redstone-rapid-demo": {
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "name": "RedStone Rapid Demo",
            "admin": "zYqPZuALSPa_f5Agvf8g2JHv94cqMn9aBtnH7GFHbuA",
            "description": "Most popular tokens with frequent updates",
            "manifestTxId": "5iQ5tPebRcdB4OjLZvaCMftREpP_P-V-F0J0WAgyLFI"
        },
        "redstone-stocks-demo": {
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "name": "RedStone Stocks Demo",
            "admin": "Yba8IVc_01bFxutKNJAZ7CmTD5AVi2GcWXf1NajPAsc",
            "description": "Stocks, ETFs, Livestocks, Metals, Energies...",
            "manifestTxId": "MmWSoOPdKoyRlJ7Qhrvp3odBu0881Ou379e19SxDxKg"
        },
        "redstone-avalanche-demo": {
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "name": "RedStone Avalanche Demo",
            "admin": "f1Ipos2fVPbxPVO65GBygkMyW0tkAhp2hdprRPPBBN8",
            "description": "Most popular tokens from the Avalanche ecosystem - demo",
            "manifestTxId": "erTjNqoLYB9Jw9zZXJPYxmijE5ElEhDM_52quvK83ws"
        },
        "redstone-avalanche-prod": {
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "name": "RedStone Avalanche Prod",
            "admin": "TEHhCDWy-vGmPSZsYJyM0aP_MM4xESgyIZdf5mVODzg",
            "description": "Most popular tokens from the Avalanche ecosystem - prod",
            "manifestTxId": "y7ppr6m9MuP65Fiivd9CX84qcPLoYBMifUrFK3jXw2k"
        },
        "redstone-primary-demo": {
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "name": "RedStone Primary Demo",
            "admin": "TEHhCDWy-vGmPSZsYJyM0aP_MM4xESgyIZdf5mVODzg",
            "description": "Data feeds from primary RedStone nodes - demo",
            "manifestTxId": ""
        },
        "redstone-primary-prod": {
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "name": "RedStone Primary Prod",
            "admin": "TEHhCDWy-vGmPSZsYJyM0aP_MM4xESgyIZdf5mVODzg",
            "description": "Data feeds from primary RedStone nodes - prod",
            "manifestTxId": ""
        },
        "redstone-arbitrum-demo": {
            "name": "RedStone arbitrum demo",
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "description": "Data feeds from dev arbitrum redstone nodes"
        },
        "redstone-arbitrum-prod": {
            "name": "RedStone arbitrum prod",
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "description": "Data feeds from arbitrum prod redstone nodes"
        },
        "redstone-fast-demo": {
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "name": "RedStone Fast Demo",
            "admin": "00HZS36ZwzP43q25-T_UV7pYlNXe9ChwDpNiM7A708c",
            "description": "Most popular tokens with fast updates",
            "manifestTxId": ""
        },
        "redstone-external-demo-1": {
            "name": "External Provider Demo",
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "description": "Data feeds from external providers, staging node"
        },
        "redstone-external-demo-2": {
            "name": "External Provider Demo",
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "description": "Data feeds from external providers, staging node"
        },
        "bwarelabs-demo-1": {
            "name": "Bware Labs Demo",
            "logo": "https://raw.githubusercontent.com/bwarelabs/brand-assets/main/infra-token/svg/infra-token-circle.svg",
            "description": "Data feeds provided by Bware Labs"
        },
        "kaiko-demo-1": {
            "name": "Kaiko Demo 1 Node",
            "logo": "https://redstone.finance/assets/img/redstone-logo-full.svg",
            "description": "Data feeds provided by Kaiko to staging env"
        }
    },
    "contractAdmins": [
        "I-5rWUehEv-MjdK9gFw09RxfSLQX9DIHxG614Wf8qo0",
        "zYqPZuALSPa_f5Agvf8g2JHv94cqMn9aBtnH7GFHbuA",
        "Yba8IVc_01bFxutKNJAZ7CmTD5AVi2GcWXf1NajPAsc",
        "33F0QHcb22W7LwWR1iRC8Az1ntZG09XQ03YWuw2ABqA",
        "BitVOfTwSSod-tUSla4nM77sUuHC4XFcT0Zlg-RZ-tI"
    ]
}
