{
    "name": "@redstone-finance/evm-connector",
    "version": "0.6.2",
    "description": "A tool to inject RedStone data into EVM compatible smart contracts",
    "main": "dist/src/index.js",
    "license": "MIT",
    "scripts": {
        "test": "hardhat test",
        "clean": "hardhat clean && rm -rf dist",
        "build": "yarn clean && yarn compile && yarn global:tsc --build tsconfig.build.json",
        "build-dev": "yarn clean && yarn compile && yarn global:tsc --build tsconfig.json",
        "build-esm": "yarn clean && yarn build && esbuild test-browser-compatibility/test-script.js --outfile=test-browser-compatibility/browser-bundle.js --format=esm --bundle=true",
        "run-in-browser": "yarn build-esm && http-server test-browser-compatibility",
        "test-browser-compatibility": "yarn build-esm && ts-node test-browser-compatibility.ts",
        "benchmark": "hardhat test benchmarks/run-benchmarks.ts",
        "compile": "hardhat compile",
        "preversion": "yarn build",
        "lint": "yarn global:eslint .",
        "lint:fix": "yarn global:eslint --fix ."
    },
    "dependencies": {
        "@chainlink/contracts": "^0.6.1",
        "@openzeppelin/contracts": "^4.8.1",
        "@redstone-finance/protocol": "0.6.2",
        "@redstone-finance/sdk": "0.6.2",
        "@redstone-finance/utils": "0.6.2",
        "axios": "^1.6.2",
        "ethers": "^5.7.2"
    },
    "devDependencies": {
        "@ethersproject/abi": "^5.6.3",
        "@ethersproject/bytes": "^5.6.1",
        "@ethersproject/providers": "^5.6.8",
        "@nomiclabs/hardhat-ethers": "^2.0.6",
        "@nomiclabs/hardhat-waffle": "^2.0.5",
        "@openzeppelin/contracts-upgradeable": "^4.8.3",
        "@typechain/ethers-v5": "^10.2.0",
        "@typechain/hardhat": "^6.1.2",
        "@types/chai": "^4.3.4",
        "@types/chai-as-promised": "^7.1.5",
        "@types/http-server": "^0.12.4",
        "@types/mocha": "^10.0.1",
        "@types/node": "^20.14.12",
        "@types/puppeteer": "^7.0.4",
        "@types/sinon-chai": "^3.2.12",
        "chai": "^4.3.10",
        "chai-as-promised": "^7.1.1",
        "esbuild": "^0.19.9",
        "ethereum-waffle": "^4.0.10",
        "hardhat": "^2.17.2",
        "hardhat-gas-reporter": "^1.0.9",
        "http-server": "^14.1.1",
        "msw": "^1.2.3",
        "puppeteer": "^22.10.0",
        "solidity-coverage": "^0.8.12",
        "ts-node": "^10.9.2",
        "typechain": "^8.1.1",
        "typescript": "^5.5.4"
    },
    "files": [
        "dist/**/*",
        "contracts/**/*"
    ],
    "publishConfig": {
        "access": "public"
    }
}
